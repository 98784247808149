import React, { useState } from "react"
import QuestionSectionStyle from "./QuestionSectionStyle.module.styl"
import Cross from "../../../../assets/images/cross.svg"
import Footer from "../../../../common/footer/Footer"

const charPoses = {
  exit: { opacity: 0, y: 0 },
  enter: {
    opacity: 1,
    y: 0,
    delay: ({ charIndex }) => charIndex * 30,
  },
}

function QuestionSection() {
  const [isVisible, setSectionVisible] = useState(false)

  function onChange() {
    setSectionVisible(!isVisible)
  }
  return (
    <section className={QuestionSectionStyle.question_section}>
      <div className={QuestionSectionStyle.question_content}>
        <div className={QuestionSectionStyle.question}>
          <h2>How do you stand out and be heard?</h2>

          <h2>With an effective Hook Point.</h2>
        </div>
        <img className={QuestionSectionStyle.cross} src={Cross} alt="cross" />
      </div>
      <Footer />
    </section>
  )
}

export default QuestionSection

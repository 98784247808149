import React from "react"
import s from "./GridSectionStyle.module.styl"
import pic1 from "../../../../assets/images/grid/1/1.png"
import pic2 from "../../../../assets/images/grid/2/2.png"
import pic3 from "../../../../assets/images/grid/3/3.png"
import pic4 from "../../../../assets/images/grid/4/4.png"

const HardCodedData = [
  {
    img: pic1,
    name: "Peter Manev",
    quote:
      "“I’ve had the pleasure of working with ST Solutions for the past 3 years. They are a great partner who always looks out for his clients and strive for improvement.”",
    position:
      "Engineering Director / General Manager Bulgaria at Resolve Systems",
  },
  {
    img: pic2,
    name: "Strahil Hadzhiev",
    quote:
      "“ST Solutions’s team always tries to challenge the constraints of the traditional product design mindset. They create experiences that leave lasting impressions to synchronize the takeaways with the core values of their client’s audience. “",
    position: "Creative Director, Prime Division",
    gray: true,
  },
  {
    img: pic3,
    name: "Faisel Durrani",
    quote:
      "“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud.”",
    position:
      "former GM/EVP of Def Jam Recordings and president of Marketing Live Nation",
    gray: true,
  },
  {
    img: pic4,
    name: "Michael Gervais",
    quote:
      "“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud.”",
    position: "CTO / Co-Founder, Siemens",
  },
]

export default function GridSection() {
  return (
    <div className={s.container}>
      {HardCodedData?.map((obj, index) => (
        <Card key={index} obj={obj} index={index} />
      ))}
    </div>
  )
}

const Card = ({ obj, index }) => {
  return (
    <div
      className={s.card}
      style={{
        backgroundColor: obj.gray ? "#EDEDED" : "#fff",
      }}
    >
      <img src={obj.img} className={s.image} />
      <div className={s.quote}>{obj.quote}</div>
      <div className={s.text}>
        <div className={s.name}> - {obj.name}</div>
        <div className={s.position}>{obj.position}</div>
      </div>
    </div>
  )
}

import React, { useState } from "react"
import AboutHeroSectionStyle from "./AboutHeroSectionStyle.module.styl"
import Footer from "../../../../common/footer/Footer"
import Cross from "../../../../assets/images/cross.svg"

const charPoses = {
  exit: { opacity: 0, y: 0 },
  enter: {
    opacity: 1,
    y: 0,
    delay: ({ charIndex }) => charIndex * 30,
  },
}

function AboutHeroSection() {
  const [isVisible, setSectionVisible] = useState(false)

  function onChange() {
    setSectionVisible(!isVisible)
  }
  return (
    <section className={AboutHeroSectionStyle.about_hero_section}>
      <div className={AboutHeroSectionStyle.make_your_business_section}>
        <div className={AboutHeroSectionStyle.make_yout_business}>
          <h2>
            We are different
            <span className={AboutHeroSectionStyle.hr} />
          </h2>

          <h3>
            Since its inception, ST Solutions has strived to provide a different
            approach to digital consultancy. One built on principles of respect
            - for our people, our clients and our work; and on agility - to move
            seamlessly from strategy to implementation, delivering value early
            and often.
          </h3>

          <img
            className={AboutHeroSectionStyle.cross}
            src={Cross}
            alt="cross"
          />
        </div>
      </div>
      <Footer />
    </section>
  )
}

export default AboutHeroSection

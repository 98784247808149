import React from "react"
import NewsletterSection from "../newsletter/NewsletterSection"
import ServicesSection from "./components/servicesSection/ServicesSection"
import UniqueAboutYouSection from "./components/uniqueAboutYouSection/UniqueAboutYouSection"
import OutSideTheBoxSection from "./components/outsideTheBoxSection/OutSideTheBoxSection"
import QuestionSection from "./components/questionSection/QuestionSection"
import AboutHeroSection from "./components/heroSection/AboutHeroSection"
import WorkSection from "../caseStudies/components/workSection/WorkSection"
import PhilosophySection from "./components/philosophySection/PhilosophySection"
import TestimonialsSection from "./components/testimonialsSection/TestimonialsSection"
import GridSection from "./components/gridSection/GridSection"

const AboutPageTemplate = ({
  uniqueSectionImage,
  questionSectionImage,
  aboutHeroSectionImage,
}) => {
  return (
    <>
      <AboutHeroSection aboutHeroSectionImage={aboutHeroSectionImage} />
      {/* <QuestionSection questionSectionImage={questionSectionImage} />
      <OutSideTheBoxSection />
      <UniqueAboutYouSection uniqueSectionImage={uniqueSectionImage} />
      <ServicesSection /> */}
      <PhilosophySection />
      {/* //!testimonials section ---------- */}
      {/* <TestimonialsSection />
      <GridSection /> */}
      {/* //!testimonials section ---------- */}
      <WorkSection />
      <NewsletterSection />
    </>
  )
}

export default AboutPageTemplate

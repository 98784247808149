import React, { useState } from "react"
import ServicesSectionStyle from "./ServicesSectionStyle.module.styl"
import Cross from "../../../../assets/images/cross.svg"

const charPoses = {
  exit: { opacity: 0, y: 0 },
  enter: {
    opacity: 1,
    y: 0,
    delay: ({ charIndex }) => charIndex * 30,
  },
}
function ServicesSection() {
  const [isVisible, setSectionVisible] = useState(false)

  function onChange() {
    setSectionVisible(!isVisible)
  }
  return (
    <section className={ServicesSectionStyle.services_section}>
      <div className={ServicesSectionStyle.no_hook_point}>
        <div className={ServicesSectionStyle.content_holder}>
          <h2>
            No hook point.
            <span>No attention</span>
          </h2>
        </div>
        <img className={ServicesSectionStyle.cross} src={Cross} alt="cross" />
      </div>
      <div className={ServicesSectionStyle.services}>
        <div className={ServicesSectionStyle.content_holder}>
          <h2>
            Our Services
            <div className={ServicesSectionStyle.line} />
          </h2>

          <div className={ServicesSectionStyle.content}>
            Hook Point development
          </div>

          <div className={ServicesSectionStyle.content}>
            Business innovation
          </div>

          <div className={ServicesSectionStyle.content}>Content strategy</div>

          <div className={ServicesSectionStyle.content}>
            Social media strategy
          </div>

          <div className={ServicesSectionStyle.content}>Marketing strategy</div>

          <div className={ServicesSectionStyle.content}>
            Communications design
          </div>

          <div className={ServicesSectionStyle.content}>
            Multiplatform / multichannel strategy
          </div>

          <div className={ServicesSectionStyle.content}>
            Data / market analysis
          </div>
        </div>
      </div>
    </section>
  )
}

export default ServicesSection

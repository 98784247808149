import React from "react"
import s from "./PhilosophySectionStyle.module.styl"
import heart from "../../../../assets/images/philosophy/heart.svg"
import diamond from "../../../../assets/images/philosophy/diamond.svg"
import mountain from "../../../../assets/images/philosophy/mountain.svg"
export default function PhilosophySection() {
  return (
    <div className={s.container}>
      <h3>Our philosophy</h3>
      <div className={s.grid}>
        <div className={s.grid_element}>
          <div className={s.img_holder}>
            <img src={heart} />
          </div>
          <div className={s.title}>
            Nothing great was ever achieved without enthusiasm
          </div>
          <div className={s.desc}>
            When tasked with a challenge we go deep into the problem so we can
            understand the core problem we’re trying to solve. Knowing this
            helps me make informed decisions and build enthusiasm for coming up
            with the best solution.
          </div>
        </div>

        <div className={s.grid_element}>
          <div className={s.img_holder}>
            <img src={diamond} />
          </div>
          <div className={s.title}>Clear experiences over unpredictability</div>
          <div className={s.desc}>
            As problem solvers, our goal is not to eliminate complexity or
            friction, but rather create a clear, intuitive and understandable
            product experience. At times, this may mean adding thoughtful
            complexity.
          </div>
        </div>

        <div className={s.grid_element}>
          <div className={s.img_holder}>
            <img src={mountain} />
          </div>
          <div className={s.title}>
            We must constantly challenge our own assumptions
          </div>
          <div className={s.desc}>
            It’s easy to become over-confident in our ideas and opinions.
            Rigorous iteration and testing can help bring clarity and confidence
            to the work.
          </div>
        </div>
      </div>
    </div>
  )
}

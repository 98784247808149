import React from "react"
import Layout from "../common/layout/layout"
import SEO from "../common/seo/seo"
import AboutPageTemplate from "../modules/about/AboutPage.template"

const About = ({ data }) => {
  return (
    <Layout>
      <SEO title="About" />
      <div className='animation'>
      <AboutPageTemplate
        uniqueSectionImage={data.uniqueSectionImage.edges}
        questionSectionImage={data.questionSectionImage.edges}
        aboutHeroSectionImage={data.aboutHeroSectionImage.edges}
      />
      </div>
    </Layout>
  )
}

export default About
export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    uniqueSectionImage: allFile(
      filter: { relativeDirectory: { eq: "uniqueSectionImage" } }
    ) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 900, quality: 100) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
    questionSectionImage: allFile(
      filter: { relativeDirectory: { eq: "questionSectionImage" } }
    ) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 900, quality: 100) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
    aboutHeroSectionImage: allFile(
      filter: { relativeDirectory: { eq: "aboutHeroSectionImage" } }
    ) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 900, quality: 100) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
  }
`
